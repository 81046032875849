/* eslint-disable prettier/prettier */
import _ from 'lodash';
import React, { useRef, useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import { keyupValidation } from '../../helpers/validationMessages';
import { Modal } from 'antd';
import { Radio } from 'antd';
const { Group } = Radio;

import OnePage from '../../components/Pages/OnePage';

import {
  isCurrentUserACustomer,
  isCurrentUserAnAdmin,
  isCurrentUserABusinessUser,
  isCurrentUserAnOwner,
} from '../../utilities/authentication';

import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { handleErrorMessages } from '../../helpers/flashMessages';
import TextInput from '../../components/TextInput';
import CurrencyInput from '../../components/CurrencyInput';
import Row from '../../components/Row';
import { useAlert } from 'react-alert';
import RadioInput from '../../components/RadioInput';
import FormHeader from '../../components/FormHeader';
import SelectInput from '../../components/SelectInput';
// import { BasicError } from '../../components/Error';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import { useHistory, useParams } from 'react-router-dom';

export const EditorContext = createContext();

const CustomerFields = ({ title, operation = 'add', submitHandler, data, error }) => {
  const history = useHistory()
  const {id} = useParams()
  const alert = useAlert();
  const shopPrivateNotesRef = useRef();
  const customerPrivateNotesRef = useRef();
  const customerPublicNotesRef = useRef();

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    updateState({ target: { name: 'discountYear', value: e.target.value } }, 'discountYear');
  };

  const [phone, setPhone] = useState(data.mobile);
  const [customer, setCustomerData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    role: '',
    ...data,
    customerId: {
      city: '',
      province: '',
      street: '',
      postalCode: '',
      labourRate: '23',
      notes: '',
      customerPrivateNotes: '',
      customerPublicNotes: '',
      status: false,
      discount: '',
      discountYear: new Date().getFullYear(),
      company: '',
      ...data.customerId,
    },
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    role: '',
    discount: '',
    discountYear: '',
    labourRate: '',
  });
  customer.mobile = phone;
  const emailString = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

  const isValidName = name => {
   // return name && /[a-zA-Z]/.test(name) && /^[a-zA-Z &@#%$*!]{2,30}$/.test(name.trim());
    //return name && /[a-zA-Z]/.test(name) && /^[a-zA-Z &@#%$*!'-.]{2,30}$/.test(name.trim());
    
    return (
      name &&
      /[a-zA-Z]/.test(name) && // Ensure at least one letter
      /^[a-zA-Z !"#$%&'()*+,\-./:;=?@[\]\\^_`{|}~]{2,30}$/.test(name.trim()) // Allows all except < >
     );
    // return name && /^[a-zA-Z &]{2,30}$/.test(name.trim());
  };

  const handleDiscountChange = (e) => {
    updateState(e, 'discount');
    if(e.target.value != "Please Select"){
      setIsModalVisible(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const generateDiscountYearOptions = () => {
    return [
      { label: currentYear, value: currentYear},
      { label: previousYear, value: previousYear}
    ];
  };

  const customerIdFields = [
    'province',
    'postalCode',
    'labourRate',
    'street',
    'city',
    'notes',
    'customerPrivateNotes',
    'customerPublicNotes',
    'status',
    'discount',
    'discountYear',
    'company',
  ];

  const modules = {
    toolbar: false,
  };

  const updateState = (e, type) => {
    const key = e.target.name;
    let value = e.target.value;
    const isCustomerIdField = customerIdFields.includes(key);
    if (key == 'mobile') {
      customer.mobile = phone;
    }
    if (isCustomerIdField) {
      if (key === 'status') {
        value = value === 'true'; // converts the string into a Boolean value
      }
      setCustomerData({ ...customer, customerId: { ...customer.customerId, [key]: value } });
    } else {
      setCustomerData({ ...customer, [key]: value });
    }
    let error = keyupValidation(e, type);
    let err = { ...errors };
    // if (phone == '' && customer.mobile == '') err.mobile = '* Enter mobile number';
    // else if (!isValidPhoneNumber(phone)) err.mobile = '* Enter valid phone number';
    // else if (phone != '' && isValidPhoneNumber(phone)) err.mobile = '';
    setErrors(err);
    if (error) {
      setErrors({ ...errors, [key]: error });
      return error;
    } else {
      setErrors({ ...errors, [key]: '' });
      return error;
    }
  };

  // const resetForm = () => {
  //   setCustomerData({
  //     firstName: '',
  //     lastName: '',
  //     mobile: '',
  //     email: '',
  //     ...data,
  //     customerId: {
  //       city: '',
  //       province: '',
  //       street: '',
  //       postalCode: '',
  //       labourRate: '',
  //       notes: '',
  //       status: false,
  //       discount: '',
  //       ...data.customerId,
  //     },
  //   });
  // };

  const checkCharacterCount = (event, refValue) => {
    const unprivilegedEditor = refValue.current.editor;
    if (unprivilegedEditor.getLength() > 600 && event.key !== 'Backspace') event.preventDefault();
  };

  const handleErrors = e => {
    let err = { ...errors };
    if (customer.email == '') err.email = '* Enter email';
    if (customer.firstName == '') err.firstName = '* Enter first name';
    else if (!isValidName(customer.firstName)) err.firstName = '* Please enter a valid name';
    else if (/^\s/.test(customer.firstName)) err.firstName = '* No leading spaces allowed';
    if (customer.lastName == '') err.lastName = '* Enter last name';
    else if (!isValidName(customer.lastName)) err.lastName = '* Please enter a valid name';
    else if (/^\s/.test(customer.lastName)) err.lastName = '* No leading spaces allowed';
    if ((isCurrentUserAnAdmin() || isCurrentUserAnOwner())) {
      if (customer?.customerId?.discount !== 0&&customer?.customerId?.discount == '') err.discount = '* Enter discount';
    }
    if ((isCurrentUserAnAdmin() || isCurrentUserAnOwner())) {
      if (customer?.customerId?.labourRate !== 0&&customer?.customerId?.labourRate == '') err.labourRate = '* Enter labour rate';
    }

    if (phone) {
      console.log("HH")
      if (!isValidPhoneNumber(phone)) err.mobile = '* Enter valid phone number';
      else if (isValidPhoneNumber(phone)) err.mobile = '';
    }
   // if (phone == undefined || phone == '' && customer.mobile == '') err.mobile = '* Enter mobile number';
    
    setErrors(err);
  };
  const onChangeError = (event)=>{
    event.persist();
    const  {name,value} = event.target;
    const decimalRegex = /^\d{1,}(\.\d{0,4})?$/
     if(name==='labourRate'){
       if (customer?.labourRate == '') setErrors((prev) => ({ ...prev, [name]: `* Enter labour rate` }));
       else if (customer?.customerId?.labourRate.toString() != ''&& customer?.customerId?.labourRate.toString().match(decimalRegex)) setErrors((prev) => ({ ...prev, [name]: `` }));
      //  else if(!customer?.labourRate?.match(decimalRegex)) setErrors((prev) => ({ ...prev, [name]: `* Please enter valid labour rate` }));
       else if (customer?.labourRate != ''&& customer.labourRate?.match(decimalRegex)) setErrors((prev) => ({ ...prev, [name]: `` }));
      
     }
    if(name==='role')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Please select role` }));
      if (value =='Please Select') setErrors((prev) => ({ ...prev, [name]: `* Please select role` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    

    }
     if(name === 'email'){
       if (customer.email == '') setErrors((prev) => ({ ...prev, [name]: `* Enter email` }));
       else if (!customer.email.match(emailString)) setErrors((prev) => ({ ...prev, [name]: `* Enter a valid email` }));
       else if (customer.email != '') setErrors((prev) => ({ ...prev, [name]: `` }));
    
      }
 
     if (name === "firstName"){
       if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Enter first name` }));
       else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
     
      }
 
     if(name === "lastName"){
     if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Enter last name` }));
     else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
     }

   }
  

  // const handleErrors = e => {
  //   let err = { ...errors };
  //   if (customer.email == '') err.email = '* Enter email';
  //   if (customer.firstName == '') err.firstName = '* Enter first name';
  //   if (customer.lastName == '') err.lastName = '* Enter last name';
  //   if ((isCurrentUserAnAdmin() || isCurrentUserAnOwner())) {
  //     if (customer?.customerId?.discount == null) err.discount = '* Enter discount';
  //   }
  //   if ((isCurrentUserAnAdmin() || isCurrentUserAnOwner())) {
  //     if (customer?.customerId?.labourRate == null) err.labourRate = '* Enter labour rate';
  //   }
  //   if (phone == undefined || phone == '' && customer.mobile == '') err.mobile = '* Enter mobile number';
  //   else if (!isValidPhoneNumber(phone)) err.mobile = '* Enter valid phone number';
  //   else if (isValidPhoneNumber(phone)) err.mobile = '';
  //   setErrors(err);
  // };

  const handlePhone = (e) => {
    setPhone(e);
    handlePhoneErrors(e)
  }
  const handlePhoneErrors = (e) => {
    let err = { ...errors };
    let phone = e;
    if (phone) {
      if (!isValidPhoneNumber(phone || data?.mobile)) err.mobile = '* Enter valid phone number';
      else err.mobile = "";  
    }else{
      err.mobile = '';
    }
    
    setErrors(err);
    // if (phone == undefined || phone == '' && data.mobile == ''){
    //   err.mobile = '* Enter mobile number';
    // } else 
    
    // else if (!isValidPhoneNumber(phone || data?.mobile)) err.mobile = '* Enter valid phone number';
    //else if ( isValidPhoneNumber(phone)) err.mobile = '';
    
  }

  const handleSubmit = e => {
    e.preventDefault();
    handleErrors();
    if ( (
          errors.role !== "* Please select role" &&
          errors.firstName == '' &&
          errors.lastName == '' &&
          errors.email == '' &&
          errors.mobile == '' &&
          errors.labourRate == '' &&
          errors.discount == '')){
      if( customer.firstName !== null && customer.firstName!==''
          &&customer.lastName !== null && customer.lastName!==''
          &&customer.email !== null && customer.email !== ''
       //   && customer.mobile !== null && customer.mobile !== ''
          && customer.labourRate !== null && customer.labourRate !== '' 
          && customer.discount !== null && customer.discount !== ''
      )
      {
        if (isValidName(customer?.firstName) && isValidName(customer?.lastName)) {
          submitHandler(customer);

       }
        //submitHandler(customer);
      }
    } 
  };
  const discountLevel = [0, 5, 10, 15];
  const roles = ['customer', 'business_user'];
  const handleOnCancelClick = e => {
    e.preventDefault();
    if (isCurrentUserACustomer()) {
      history.push(`/customer/${data._id}`)
    }
    if(isCurrentUserAnOwner() ) {
      if(id) {
        history.push(`/customer/${id}`)
      }else {
        history.push(`/dashboard`)
      }
    }
    if(isCurrentUserABusinessUser()) {
      history.push(`/customer/${id}`)
    }
  }
  return (
    <OnePage background="background">
      <div className="px-0 sm:px-5 md:px-20 pt-10 h-hero">
        <div className="flex flex-col h-auto px-2 w-100">
          <FormHeader
            title={title}
            backLink={`${operation === 'add' ? '/dashboard' : `/customer/${data._id}`}`}
          />
          {/* {error && <BasicError customError={error} />} */}
          <form
            className="flex flex-col px-5 py-6 bg-white app_form"
            onSubmit={e => handleSubmit(e)}
            noValidate
          >
            <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
              <div>
              {(isCurrentUserAnAdmin() || isCurrentUserAnOwner()) && (
                <>
                <SelectInput
                  label="Role"
                  name="role"
                  value={customer?.role}
                  onChange={e => updateState(e)}
                  required={true}
                  onBlur={onChangeError}
                >
                  {roles.map((role, i) => (
                    <option key={i.toString()} value={role}>
                      {_.startCase(role)}
                    </option>
                  ))}
                
                </SelectInput>
             
                </>
              )}
               {errors && errors.role !== '' ? (
                    <p className="text-error font text-sm">{errors.role}</p>
                  ) : null}
              </div>
              {customer?.role === 'business_user' && (
                <TextInput
                  label="Company"
                  onChange={e => updateState(e)}
                  name="company"
                  value={customer?.customerId?.company}
                  required={false}
                  placeholder="Company Name"
                />
              )}
              <div>
                <TextInput
                  label="First Name"
                  onChange={e => updateState(e, 'firstName')}
                  name="firstName"
                  value={customer?.firstName}
                  required={true}
                  placeholder="John"
                  onBlur={onChangeError}
                />
                {errors && errors.firstName !== '' ? (
                  <p className="text-error font text-sm">{errors.firstName}</p>
                ) : null}
              </div>
              <div>
                <TextInput
                  label="Last Name"
                  onChange={e => updateState(e, 'lastName')}
                  name="lastName"
                  value={customer?.lastName}
                  required={true}
                  placeholder="Doe"
                  onBlur={onChangeError}
                />
                {errors && errors.lastName !== '' ? (
                  <p className="text-error font text-sm">{errors.lastName}</p>
                ) : null}
              </div>
              <div>
                <label>Mobile Number</label>
                {/* <RequiredAsterisk /> */}
                <PhoneInput
                  label="Mobile"
                  international
                  countryCallingCodeEditable={true}
                  //defaultCountry="CA"
                  onChange={ e => handlePhone(e)}
                  name="mobile"
                  value={phone}
                  required={true}
                  placeholder="123-456-7890"
                  onBlur={handleErrors}
                />
                {errors && errors.mobile !== '' ? (
                  <p className="text-error font text-sm">{errors.mobile}</p>
                ) : null}
              </div>
              <div>
                <TextInput
                  label="Email"
                  onChange={e => updateState(e, 'email')}
                  name="email"
                  value={customer?.email}
                  required={true}
                  placeholder="john.doe@mail.com"
                  onBlur={onChangeError}
                />
                {errors && errors.email !== '' ? (
                  <p className="text-error font text-sm">{errors.email}</p>
                ) : null}
              </div>
              <TextInput
                label="Street"
                onChange={e => updateState(e)}
                name="street"
                value={customer?.customerId?.street}
                required={false}
                placeholder="e.g 100 Bayview Ave"
                
              />
              <TextInput
                label="City"
                onChange={e => updateState(e)}
                name="city"
                value={customer?.customerId?.city}
                required={false}
                placeholder="Toronto"
              />
              <TextInput
                label="Province"
                onChange={e => updateState(e)}
                name="province"
                value={customer?.customerId?.province}
                required={false}
                placeholder="Ontario"
              />
              <TextInput
                label="Postal Code"
                onChange={e => updateState(e)}
                name="postalCode"
                value={customer?.customerId?.postalCode}
                required={false}
                placeholder="e.g M1M 1M1"
                maxLength="11"
              />
                {(isCurrentUserAnAdmin() || isCurrentUserAnOwner()) && (
                  <>
                  <div>
                  <SelectInput
                    label="Discount Level"
                    name="discount"
                    value={customer?.customerId?.discount}
                    onChange={handleDiscountChange}
                    required={true}
                    defaultOption="Select Discount Level"
                    onBlur={onChangeError}
                  >
                    {discountLevel.map((level, i) => (
                      <option key={i.toString()} value={level}>
                        {level + '%'}
                      </option>
                    ))}
                  </SelectInput>
                  {errors && errors.discount !== '' ? (
                  <p className="text-error font text-sm">{errors.discount}</p>
                ) : null}
                </div>
                  </>
                )}
                <Modal
                  title="Select discount year"
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  maskClosable={false}
                  footer={null}
                  preserve={false}
                  destroyOnClose={true}
                  bodyStyle={{ height: 100,}}
                >
                  <p className="font-medium text-base mb-2">
                    <span className="block text-primary text-sm"></span>                  
                    <Group
                      options={generateDiscountYearOptions()}
                      onChange={handleYearChange}
                      value={selectedYear}
                      style={{ width: 310, marginRight: 10 }}
                    />
                    <button
                      type="submit"
                      className="px-5 py-3 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
                      onClick={() => { setIsModalVisible(false) }}
                    >
                      Ok
                    </button>
                  </p>
                </Modal>
                
              {!(isCurrentUserACustomer() || isCurrentUserABusinessUser()) && (
                <>
                  <div>
                    <TextInput
                      label="Labour Rate / Hour"
                      onChange={e => updateState(e, 'labourRate')}
                      name="labourRate"
                      value={customer?.customerId?.labourRate}
                      required={true}
                      placeholder="0.00"
                      onBlur={onChangeError}
                    />
                    {errors && errors.labourRate !== '' ? (
                      <p className="text-error font text-sm">{errors.labourRate}</p>
                    ) : null}
                  </div>

                  {operation === 'edit' && (
                    <div className="flex flex-col">
                      <label className="text-small text-text">Status</label>
                      <div className="flex flex-row mt-3 mb-10 ">
                        <div className='radio-item-active'>
                    
                        <RadioInput
                          label="Active"
                          defaultChecked={customer?.customerId?.status === true}
                          onClick={e => updateState(e)}
                          value="true"
                          name="status"
                        />
                            </div>
                            <div  className='radio-item-inactive '>
                        <RadioInput
                          label="Inactive"
                          defaultChecked={customer?.customerId?.status === false}
                          onClick={e => updateState(e)}
                          value="false"
                          name="status"
                        />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col  w-full col-span-full">
                    <label className="text-small text-text mb-2">
                      <div>
                        Shop&apos;s Private Notes
                        {<span className="grey_text"> (max 600 characters) </span>}
                      </div>
                    </label>
                    <ReactQuill
                      className="notes-editor-height"
                      onKeyDown={e => checkCharacterCount(e, shopPrivateNotesRef)}
                      ref={shopPrivateNotesRef}
                      name="notes"
                      modules={modules}
                      onChange={value =>
                        setCustomerData({
                          ...customer,
                          customerId: { ...customer.customerId, notes: value },
                        })
                      }
                      value={customer?.customerId?.notes || ''}
                    />
                  </div>
                </>
              )}
              {operation === 'edit' && (isCurrentUserACustomer() || isCurrentUserABusinessUser()) && (
                <div className="flex flex-col  w-full col-span-full">
                  <label className="text-small text-text mb-2">
                    <div>
                      Customer Private Notes
                      {<span className="grey_text"> (max 600 characters) </span>}
                    </div>
                  </label>
                  <ReactQuill
                    className="notes-editor-height"
                    onKeyDown={e => checkCharacterCount(e, customerPrivateNotesRef)}
                    ref={customerPrivateNotesRef}
                    name="customerPrivateNotes"
                    modules={modules}
                    onChange={value =>
                      setCustomerData({
                        ...customer,
                        customerId: { ...customer.customerId, customerPrivateNotes: value },
                      })
                    }
                    value={customer?.customerId?.customerPrivateNotes || ''}
                  />
                </div>
              )}
              {isCurrentUserAnOwner() && (
                <div className="flex flex-col  w-full col-span-full">
                  <label className="text-small text-text mb-2">
                    <div>
                      Customer&apos;s Public Notes
                      {<span className="grey_text"> (max 600 characters) </span>}
                    </div>
                  </label>
                  <ReactQuill
                    className="notes-editor-height"
                    onKeyDown={e => checkCharacterCount(e, customerPublicNotesRef)}
                    ref={customerPublicNotesRef}
                    name="customerPublicNotes"
                    modules={modules}
                    onChange={value =>
                      setCustomerData({
                        ...customer,
                        customerId: { ...customer.customerId, customerPublicNotes: value },
                      })
                    }
                    value={customer?.customerId?.customerPublicNotes || ''}
                  />
                </div>
              )}
            </Row>
            <div className="text-right">
            <button
               onClick={handleOnCancelClick}
                className="px-5 py-3 mt-5 mx-2 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
              >
               Cancel
              </button>
              <button
                type="submit"
                className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
              >
                {operation === 'add' ? 'Add Customer' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </OnePage>
  );
};

CustomerFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.string,
};

export default CustomerFields;