/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useContext } from 'react';
import EditProfile from '../../../components/Settings/EditProfile';
import ChangePassword from '../../../components/Settings/ChangePassword';
import { getTokenPayload } from '../../../utilities/token';
import UserAPIs from '../../../api/user';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';
import { useAlert } from 'react-alert';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {RenderContentContextt} from '../../../routes/AdminRoute';
import {keyupValidation} from '../../../helpers/validationMessages'
import AppContext from '../../../AppContext';
import _ from 'lodash';
import { Input, Modal } from 'antd';
import RequiredAsterisk from '../../../components/RequiredAsterisk';

const Settings = () => {
    const alert = useAlert();
    const { id, email: loggedInEmail } = getTokenPayload();
    const [profileImage, setProfileImage] = useState('');
    const [preview, setPreview] = useState();
    const [editProfile, setEditProfile] = useState({
        firstName: "",
        lastName: "",
        email: "",
        image: "",
        profilePic:""
    });

    const [email,setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [updatedUserDetails, setUpdatedUserDetails] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [passcode, setPasscode] = useState('');
    const [counter, setCounter] = useState(59);
    const [changePassword, setChangePassword] = useState({
        userEmail: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email:""
      });
    const [changePassErrors, setChangePassErrors] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });

    const { updatedData} = useContext(RenderContentContextt)||{};
    const { updatedUserData} = useContext(AppContext)||{};

   

    
    const [isApiCall, setIsApiCall] = useState(false);
    const handleErrors = () => {
        let err = { ...errors };
        if (!editProfile.firstName) err.firstName = 'First Name is required';
        else if (!isValidName(editProfile.firstName)) err.firstName = 'Enter a valid first name';
        else if (editProfile.firstName) err.firstName = '';
        if (!editProfile.lastName) err.lastName = 'Last Name is required';
        else if (!isValidName(editProfile.lastName)) err.lastName = 'Enter a valid last name';
        else if (editProfile.lastName) err.lastName = '';
        if (!phone) err.mobile = 'Phone Number is required';
        else if(!isValidPhoneNumber(phone)) err.mobile ='Please enter valid number';
        else if (phone) err.mobile = '';
        setErrors(err);
      };

    useEffect(() => {
        (async () => {
            try {
              const { data } = await UserAPIs.getUser(id);
              const { user } = data;
              setEditProfile({
                ...editProfile,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                profilePic:user.profilePic
              })
              setEmail(user.email)
              setPhone(user.mobile || "");
              
              setProfileImage(`${process.env.REACT_APP_S3_BUCKET_URL}${user.profilePic}`)
            } catch (e) {
              console.log(e);
            }
          })();
        
    },[])

      useEffect(() => {
        if (isModalVisible) {
          counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        } else if (!isModalVisible) {
          setCounter(59);
        }
      }, [isModalVisible, counter]);

    const handleOnProfileChange = (e) => {
        const { name, value } = e.target;
        if(name === "mobile"){
            setPhone(value)
        }
        setEditProfile({...editProfile, [name]: value });

        let error = keyupValidation(e, name);
        let err = { ...errors };
        setErrors(err);
        if (error) {
          setErrors({ ...errors, [name]: error });
          return error;
        } else {
          setErrors({ ...errors, [name]: '' });
          return error;
        }

    }

    const handleUploadImage = async e => {
      if(!e.target.files.length > 0) {
        return
      }
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(objectUrl);
      setEditProfile({...editProfile, image: e.target.files[0]})
    }

    const isValidName = name => {
        return name && /^[a-zA-Z ]{2,30}$/.test(name);
      };

      const updateProfile = async (data) => {
        setIsApiCall(true);
        const formData = new FormData();
        
        try {
          formData.append('firstName', data.firstName);
          formData.append('lastName', data.lastName);
          formData.append('email', data.email);
          formData.append('mobile', data.mobile);
          if (editProfile.image) {
            formData.append(`image`, editProfile.image, editProfile.image.name);  
          }
          
          const response = await UserAPIs.updateCustomer(formData, id);
            localStorage.setItem('Fname', editProfile.firstName);
            localStorage.setItem('Lname', editProfile.lastName);
            localStorage.setItem('Profile', profileImage);
            localStorage.setItem('preview',preview)
         
            if(response&&updatedData!==undefined){
              setTimeout(() => {
              updatedData();
                  }, 1000);
                }
                if(response&&updatedUserData!==undefined){
                  setTimeout(() => {
                    updatedUserData();
                      }, 1000);
                    }
                    handleSuccessMessages(response, alert);
                
          setIsApiCall(false);
        } catch (e) {
          const message = handleErrorMessages(e, alert);
          setErrors(message);
          setIsApiCall(false);
        }
      };

    const handleOnProfileUpdate = async e => {
        handleErrors();
        if((editProfile.firstName && editProfile.email) !== "" && (errors.firstName && errors.phone) === ""){
            if (isValidName(editProfile?.firstName) && isValidName(editProfile?.lastName)) {
                if (phone && phone.length >= 10 && isValidPhoneNumber(phone)) {
                  const isEmailSame = _.isEqual(email, editProfile.email);
                  if (!isEmailSame){
                    try {
                      const response = await UserAPIs.verifyEmail(editProfile.email);
                      handleSuccessMessages(response, alert);
                      setIsModalVisible(true);
                    const updatedOwner = { ...editProfile, mobile: phone };
                      setUpdatedUserDetails(updatedOwner)
                    } catch (error) {
                      const message = handleErrorMessages(error, alert);
                      setErrors(message);
                    }
                  }
                  else{
                    const updatedOwner = { ...editProfile, mobile: phone };
                    updateProfile(updatedOwner)
                  }
                }
              
              }
        }
    }

      const handleCancel = () => {
        setIsModalVisible(false);
        setPasscode('');
      };

      const resendOtp = async e => {
        e.preventDefault();
        const { email } = updatedUserDetails;
        try {
          const response = await UserAPIs.verifyEmail(email);
          handleSuccessMessages(response, alert);
        } catch (error) {
          const message = handleErrorMessages(error, alert);
          setErrors(message);
        }
      };

const onFinish = async e => {
  e.preventDefault();
  if (!passcode || passcode == '') {
    return handleErrorMessages({ customMessage: 'Please enter the passcode!' }, alert);
  }
  const { email, mobile } = updatedUserDetails;
  const body = {
    email: email,
    passcode: passcode,
  };
  try {
    await UserAPIs.verifyEnteredPasscode(body);
    updateProfile(updatedUserDetails);
  } catch (err) {
    const message = handleErrorMessages(err, alert);
    setErrors(message);
  }
  setIsModalVisible(false);
};

    const handleChangePassErrors = () => {
        let err = { ...changePassErrors };
        if (changePassword.currentPassword === '') err.currentPassword = '* Enter Current Password';
        else if(changePassword.currentPassword) err.currentPassword = "";
        if (changePassword.newPassword === '') err.newPassword = '* Enter New Password';
        else if(changePassword.newPassword) err.newPassword = "";
        if (changePassword.confirmPassword === '') err.confirmPassword = '* Enter Confirm Password';
        else if(changePassword.confirmPassword) err.confirmPassword = "";
        if(changePassword.newPassword !== changePassword.confirmPassword) err.confirmPassword = "* Password should match to new password";
        else if (changePassword.newPassword === changePassword.confirmPassword) err.confirmPassword = "";
        setChangePassErrors(err);
      };

    const handleOnPasswordChange = (e) => {
        const { name, value } = e.target;
        setChangePassword({...changePassword, [name]: value });
    }
    
    const updatePassword = async (data) => {
      handleErrors();
      if (changePassErrors.currentPassword === '' && changePassErrors.newPassword === '' && changePassErrors.confirmPassword === '') 
        try {
          const response = await UserAPIs.changeUserPassword(data);
          handleSuccessMessages(response, alert);

          setChangePassword({
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
          });
        } catch (e) {
          const message = handleErrorMessages(e, alert);
          setErrors(message);
        }
      };

    const handleOnPasswordUpdate = (e) => {
       let err = { ...changePassErrors };
        handleChangePassErrors();
        if(changePassword.currentPassword === '' || changePassword.newPassword === '' || changePassword.confirmPassword === ''){
          if (changePassword.currentPassword === '') err.currentPassword = '* Enter Current Password';
          if (changePassword.newPassword === '') err.newPassword = '* Enter New Password';
          if (changePassword.confirmPassword === '') err.confirmPassword = '* Enter Confirm Password';
          setChangePassErrors(err);
        
        }else if(changePassword.newPassword !== changePassword.confirmPassword){

          err.confirmPassword = "* Password should match to new password";
          setChangePassErrors(err);

        }else {
          err.currentPassword = "";
          err.newPassword = "";
          err.confirmPassword = "";
          setChangePassErrors(err);
          const updatedPassData = { email: loggedInEmail, oldPassword: changePassword.currentPassword, newPassword: changePassword.newPassword };
          updatePassword(updatedPassData);

        }
        // if((changePassword.currentPassword && changePassword.newPassword && changePassword.confirmPassword) !== "" && (changePassErrors.currentPassword && changePassErrors.newPassword && changePassErrors.confirmPassword) === ""){
        //     const updatedPassData = { email: loggedInEmail, oldPassword: changePassword.currentPassword, newPassword: changePassword.newPassword };
        //     updatePassword(updatedPassData);
        // }
    }

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">Settings</h2>
        </div>
        <EditProfile
          state={editProfile}
          phone={phone}
          setPhone={setPhone}
          errors={errors}
          handleChange={handleOnProfileChange}
          preview={preview}
          profileImage={profileImage}
          handleImgChange={handleUploadImage}
          handleUpdate={handleOnProfileUpdate}
          isApiCall={isApiCall}
          setErrors={setErrors}
        />
        <ChangePassword
          state={changePassword}
          handleChange={handleOnPasswordChange}
          errors={changePassErrors}
          handleUpdate={handleOnPasswordUpdate}
        />
      </div>
      <Modal
        title="Verify OTP"
        visible={isModalVisible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        preserve={false}
        destroyOnClose={true}
        bodyStyle={{ height: 270 }}
      >
        <p className="text-center font-medium text-base mb-5">
          Please enter the passcode sent to this email:
          <span className="block text-primary text-sm"> {updatedUserDetails?.email} </span>
        </p>
        <form className="flex flex-col" onSubmit={e => onFinish(e)}>
          <div>
            <label className="text-bold">
              Passcode <RequiredAsterisk />
            </label>
            <div className="max-h-3px">
              <Input
                name="passcode"
                value={passcode}
                className="mt-1 mb-3"
                onChange={e => setPasscode(e.target.value)}
              />
            </div>
          </div>
          {counter > 0 ? (
            <div className="timer_count text-center mt-2">
              <p className="text-gray-1">
                Resend OTP in <span className="text-primary font-bold">{counter}s</span>
              </p>
            </div>
          ) : (
            <div className="timer_count text-center mt-2">
              <a
                className="underline cursor-pointer"
                onClick={e => {
                  setCounter(59);
                  resendOtp(e);
                }}
              >
                Resend OTP
              </a>
            </div>
          )}
          <div>
            <button
              type="submit"
              className="px-5 py-3 mt-5 text-white rounded-sm bg-primary hover:bg-primary-light modal-button"
            >
              Submit
            </button>
            <button
              type="button"
              className="px-5 py-3 mt-5 mr-3 text-white rounded-sm bg-secondary_disabled modal-button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Settings;
