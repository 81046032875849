/* eslint-disable prettier/prettier */
import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import nhtsa from 'nhtsa';
import { Select } from 'antd';
import _ from 'lodash';
import AdminAPIs from '../../../api/admin';

import TextInput from '../../../components/TextInput';
import OnePage from '../../../components/Pages/OnePage';
import Row from '../../../components/Row';
import FormHeader from '../../../components/FormHeader';
import TextArea from '../../../components/TextArea';
import { BasicError } from '../../../components/Error';
import {
  isCurrentUserACustomer,
  isCurrentUserAnAdmin,
  isCurrentUserABusinessUser,
  isCurrentUserAnOwner,
} from '../../../utilities/authentication';
import { validationcommon, keyupValidation } from '../../../helpers/validationMessages';
import RequiredAsterisk from '../../../components/RequiredAsterisk';
// import SelectInput from '../../../components/SelectInput';

const VehicleFields = ({ title, operation = 'add', submitHandler, vehicle,vinNumber, parentError }) => {
  const [error, setError] = useState(null);
  const [vinEditError,setVinEditError] = useState('');
  const vehicleInitialState = {
    // customerId: vehicle?.customerId ?? '',
    driverName: vehicle?.driverName ?? '',
    plateNumber: vehicle?.plateNumber ?? '',
    vin: vehicle?.vin ?? '',
    make: vehicle?.make ?? '',
    model: vehicle?.model ?? '',
    color: vehicle?.color ?? '',
    body: vehicle?.body ?? '',
    transmission: vehicle?.transmission ?? '',
    engine: vehicle?.engine ?? '',
    yearManufactured: vehicle?.yearManufactured ?? '',
    ac: vehicle?.ac ?? true,
    active: vehicle?.active ?? true,
    trim: vehicle?.trim ?? '',
    notes: vehicle?.notes ?? '',
    customerPrivateNotes: vehicle?.customerPrivateNotes ?? '',
  };
  const custId = {customerId:vehicle?.customerId ?? ''}
  const [vehicleData, setVehicleData] = useState(vehicleInitialState);
  const [errors, setErrors] = useState({
    customer: '',
    plateNumber: '',
    vin: '',
    make: '',
    model: '',
    engine: '',
    yearManufactured: '',
  });
  const { Option, OptGroup } = Select;
  const [options, setOptions] = useState({
    role: 'customer' || 'business_user',
    search: '',
    status: '',
    garage: '605b5b226ec4715ce5e0e00d',
    customer: '',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
    start: '',
    end: '',
  });
  const [customers, setCustomers] = useState([]);

  const { customerId } = useParams();

  const handleErrors = () => {
    let err = { ...errors };
    if (operation == 'add' && options.customer == '') err.customer = '* Select a customer';
    if (vehicleData.plateNumber.trim() == '') err.plateNumber = '* Enter plate number';
    if (vehicleData.yearManufactured == '') err.yearManufactured = '* Enter year of manufacturing';
    if (vehicleData.make.trim() == '') err.make = '* Enter make of vehicle';
    if (vehicleData.model.trim() == '') err.model = '* Enter model of vehicle';
    if (vehicleData.engine == '') err.engine = '* Enter capacity of engine';
    if (vehicleData.vin.trim() == '') err.vin = '* Enter V.I.N.';
    setErrors(err);
  };
  useEffect(() => {
    (async () => {
      try {
        //const response = await AdminAPIs.getCustomersName ();
        const response = await AdminAPIs.getCustomersAndBusinessUsersName ();
        const { usersName } = response.data;
       setCustomers(usersName);
      } catch (e) {
        console.error('error in catch', e);
      }
    })();
  }, []);
  const updateState = (e, type) => {
    const key = e.target.name;
    let value = e.target.value;

    if(key =='vin' && operation =='edit')
    {
      setVinEditError('old vin number should not be replaced with new vin number.')
    }

    if (key == 'plateNumber') {
      value = value.toUpperCase();
    } else if (key == 'vin') {
      value = value.toUpperCase();
    }
    setVehicleData({ ...vehicleData, [key]: value });
    let error = keyupValidation(e, type);
    if (error) {
      setErrors({ ...errors, [key]: error });
      return error;
    } else {
      setErrors({ ...errors, [key]: '' });
      return error;
    }
  };

  const onSelect = e => {
    const newOptions = {
      customers: e,
    };
    setOptions({ ...options, customer: e });
    if (newOptions.customers != '')
    {
      setErrors({ ...errors, customer: ''});
    }
  };
  const handleVin=(e)=>{
    const {name,value}=e.target;

    if(vehicleData.vin == vinNumber)
    {
      setVinEditError('')
    }
  }

  const onSearch = value => console.log(value);
  function handleChange(value) {}
  const addVehicle = e => {
    e.preventDefault();
    handleErrors();
    if (
      (vehicleData.plateNumber.trim() &&
        vehicleData.vin.trim() &&
        vehicleData.make.trim() &&
        vehicleData.model.trim() &&
        vehicleData.engine &&
        vehicleData.yearManufactured) != '' &&
      errors.customer == '' &&  
      errors.engine == '' &&
      errors.yearManufactured == '' &&
      errors.plateNumber == '' &&
      errors.engine == '' &&
      errors.vin == '' &&
      errors.model == ''
    )
      submitHandler(vehicleData,options.customer);
      // handleSubmit(vehicleData);
  };

  const findVinAndUpdateVehicleDataState = useCallback(async (vin, vehicleInfo) => {
    try {
      const { data } = await nhtsa.decodeVinExtendedFlatFormat(vin);

      if (data) {
        const { Make, Model, BodyClass, TransmissionStyle, DisplacementL, ModelYear } = data.Results[0];
        setVehicleData({
          ...vehicleInfo,
          make: Make,
          model: Model,
          body: BodyClass,
          transmission: TransmissionStyle,
          engine: DisplacementL,
          yearManufactured: ModelYear,
        });
      }

      if (data.Results[0].ErrorText) {
        if (data.Results[0].ErrorText.includes('Manufacturer is not registered with NHTSA')) {
          setError(
            'The VIN belongs to a vehicle that is not registered with NHTSA. Unfortunately, details about the vehicle cannot be found'
          );
        }
      }
    } catch (error) {
      setError({ ...error });
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    submitHandler();
  };

  useEffect(() => {
    const isAddOperation = operation === 'add';
    const isValidVin = vehicleData?.vin !== '';
    const hasNoResultsYet = vehicleData?.make?.length === 0;

    if (isAddOperation && isValidVin && hasNoResultsYet && !error) {
      findVinAndUpdateVehicleDataState(vehicleData.vin, vehicleData);
    }
  }, [vehicleData.vin, operation, vehicleData, findVinAndUpdateVehicleDataState, error]);

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/admin/vehicles">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">
                {operation === 'add' ? 'Add Vehicle' : 'Edit Vehicle'}
              </p>
            </Link>
            {/* </button> */}
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        {/* <form className="flex flex-col px-5 py-6 bg-white app_form" onSubmit={e => handleSubmit(e)}> */}
        <form
            className="flex flex-col px-5 py-6 bg-white flex-wrap app_form"
            onSubmit={e => addVehicle(e)}
            noValidate
          >
            <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
              {operation === 'add' ? <div className='flex flex-col'>
                <label className='inline text-small text-text mb-1'>Select Customer/Business User <RequiredAsterisk /></label>
              <Select
              label="Select Customer"
              popupClassName='px-4 py-3 bg-white text-body-2 text-text focus:outline-none rounded'
              showSearch
              placeholder="Select a Customer"
              optionFilterProp="children"
              onSelect={e => {
                onSelect(e);
              }}
              onChange={e => {
                setOptions({ ...options, customer: e });
                // setVehicleData({ ...vehicleData, customerId:e });
              }}
              onSearch={onSearch}
              defaultValue='Please Select'
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {customers.map((customer, i) => (
                <option key={i.toString()} value={customer.id}>
                  {_.startCase(customer.firstName).concat(' ').concat(customer.lastName)}
                </option>
              ))}
            </Select>
            {errors && errors.customer !== '' ? (
                  <p className="text-error font text-sm">{errors.customer}</p>
                ) : null}
              </div> : ''}
              <div>
                <TextInput
                  label="Plate Number"
                  name="plateNumber"
                  value={vehicleData.plateNumber}
                  onChange={e => updateState(e, 'plateNumber')}
                  required={true}
                  placeholder="eg: CMMK345"
                  maxLength={15}
                />
                {errors && errors.plateNumber !== '' ? (
                  <p className="text-error font text-sm">{errors.plateNumber}</p>
                ) : null}
              </div>
              <div>
                <TextInput
                  label="Year Manufactured"
                  name="yearManufactured"
                  value={vehicleData.yearManufactured}
                  onChange={e => updateState(e, 'year')}
                  required={true}
                  placeholder="eg: 2021"
                  maxLength={4}
                />
                {errors && errors.yearManufactured !== '' ? (
                  <p className="text-error font text-sm">{errors.yearManufactured}</p>
                ) : null}
              </div>
              <div>
                <TextInput
                  label="Make"
                  name="make"
                  value={vehicleData.make}
                  onChange={e => updateState(e, 'make')}
                  required={true}
                  placeholder="eg: SUBARU"
                />
                {errors && errors.make !== '' ? (
                  <p className="text-error font text-sm">{errors.make}</p>
                ) : null}
              </div>
              <div>
                <TextInput
                  label="Model"
                  name="model"
                  value={vehicleData.model}
                  onChange={e => updateState(e, 'model')}
                  required={true}
                  placeholder="Forester"
                />
                {errors && errors.model !== '' ? (
                  <p className="text-error font text-sm">{errors.model}</p>
                ) : null}
              </div>
              <TextInput
                label="Trim"
                name="trim"
                value={vehicleData?.trim}
                onChange={e => updateState(e)}
                placeholder="eg: CE"
              />
              <div>
                <TextInput
                  label="Engine"
                  name="engine"
                  value={vehicleData.engine}
                  onChange={e => updateState(e, 'engine')}
                  required={true}
                  placeholder="2.0"
                />
                {errors && errors.engine !== '' ? (
                  <p className="text-error font text-sm">{errors.engine}</p>
                ) : null}
              </div>
              <TextInput
                label="Transmission"
                name="transmission"
                value={vehicleData.transmission}
                onChange={e => updateState(e)}
                required={false}
                placeholder="eg: Automatic"
              />
              <TextInput
                label="Body"
                name="body"
                value={vehicleData.body}
                onChange={e => updateState(e)}
                required={false}
                placeholder="eg: Wagon"
              />
              <div>
                <TextInput
                  label="V.I.N"
                  name="vin"
                  value={vehicleData.vin}
                  onChange={e => updateState(e, 'VIN')}
                  required={true}
                  placeholder="eg: JH5676FG56GDE6789"
                  onBlur={ operation === 'edit' && handleVin}
                  //maxLength={17}
                />
                  {vinEditError !== '' ? <p ><b>{vinEditError}</b></p> : null}
                {errors && errors.vin !== '' ? <p className="text-error font text-sm">{errors.vin}</p> : null}
              </div>
              <TextInput
                label="Color"
                name="color"
                value={vehicleData.color}
                onChange={e => updateState(e)}
                required={false}
                placeholder="eg: Red"
              />
              <TextInput
                label="Driver Name"
                name="driverName"
                value={vehicleData.driverName}
                onChange={e => updateState(e)}
                required={false}
                placeholder="eg: John Doe"
              />
              <div className="flex flex-col w-full">
                <label className="text-small text-text">Status</label>
                <div className="flex flex-row mt-3 mb-4">
                  <div className="flex items-center mr-5 radio-item-active">
                    <input
                      defaultChecked={vehicleData.active === true}
                      onChange={e => updateState(e)}
                      value="true"
                      type="radio"
                      name="active"
                      className="mr-2 text-primary"
                      required={false}
                    />
                    <label className="text-small text-text">Active</label>
                  </div>
                  <div className="flex items-center mr-5 radio-item-inactive">
                    <input
                      defaultChecked={vehicleData.active === false}
                      onChange={e => updateState(e)}
                      value="false"
                      type="radio"
                      name="active"
                      className="mr-2 text-primary"
                      required={false}
                    />
                    <label className="text-small text-text">Inactive</label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <label className="text-small text-text">A/C</label>
                <div className="flex flex-row mt-3 mb-4">
                  <div className="flex items-center mr-5">
                    <input
                      defaultChecked={vehicleData.ac === true}
                      onChange={e => updateState(e)}
                      value="true"
                      type="radio"
                      name="ac"
                      className="mr-2 text-primary"
                      required={false}
                    />
                    <label className="text-small text-text">Yes</label>
                  </div>
                  <div className="flex items-center mr-5">
                    <input
                      defaultChecked={vehicleData.ac === false}
                      onChange={e => updateState(e)}
                      value="false"
                      type="radio"
                      name="ac"
                      className="mr-2 text-primary"
                      required={false}
                    />
                    <label className="text-small text-text">No</label>
                  </div>
                </div>
              </div>
              {isCurrentUserAnOwner() && (
                <TextArea
                  label="Shop's Private Notes"
                  onChange={e => updateState(e)}
                  name="notes"
                  value={vehicleData?.notes}
                  required={false}
                  placeholder="Enter relevant notes"
                />
              )}
              {(isCurrentUserACustomer() || isCurrentUserABusinessUser()) && (
                <TextArea
                  label="Customer Private Notes"
                  onChange={e => updateState(e)}
                  name="customerPrivateNotes"
                  value={vehicleData?.customerPrivateNotes}
                  required={false}
                  placeholder="Enter relevant notes"
                />
              )}
            </Row>
            <div className="text-right">
              <button
                type="submit"
                className="px-5 py-3 mt-5 text-white rounded-sm w-36 text-button bg-primary hover:bg-opacity-80"
              >
                {operation === 'add' ? 'Add Vehicle' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
        </>
  );
};

VehicleFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  vehicle: PropTypes.object,
  vinNumber: PropTypes.string,
  parentError: PropTypes.string,
};

export default VehicleFields;
