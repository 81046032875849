/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';

import AdminAPIs from '../../../api/admin';

import GarageFields from './garageFields';

const EditGarage = () => {
  const [error, setError] = useState('');
  const { id } = useParams();
  const [owner, setOwner] = useState({});
  const history = useHistory();
  const alert = useAlert();

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getOwner(id);
        const { getOwner } = response.data;
        setOwner({ ...getOwner });
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, [id]);

  const updateState = e => {
    const key = e.target.name;
    let value = e.target.value;
    setOwner({ ...owner, [key]: value });
  };

  const onSubmit = async () => {
    try {
      console.log('owner',owner);
     // const response = await AdminAPIs.updateGarageName(id, owner.ownerId.garage._id, owner)
     // const response = await AdminAPIs.updateGarageName(id, owner)
      const response = await AdminAPIs.updateGarageName(id, {
        garage:{garageName:owner.ownerId.garage.name,
          garageId:owner.ownerId.garage._id
        },
        firstName: owner.firstName,
        lastName: owner.lastName,
        mobile: owner.mobile,
        email: owner.email,
      });
      history.push('/admin/garages');
      handleSuccessMessages(response, alert);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    }
  };

  return (
    <GarageFields
      operation="edit"
      updateState={updateState}
      setOwner={setOwner}
      submitHandler={onSubmit}
      data={owner}
    />
  );
};

export default EditGarage;
