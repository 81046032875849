/* eslint-disable prettier/prettier */
import HttpService from '../services/http';

const getInvoice = invoiceId => {
  const http = new HttpService(`/invoice/${invoiceId}`);
  return http.get();
};

const editInvoice = (prevVehicleId, customerId, vehicleId, invoiceId, body) => {
  const http = new HttpService(`/invoice/${customerId}/${vehicleId}/${invoiceId}/${prevVehicleId}`);
  return http.put(body);
};

const addInvoice = (customerId, vehicleId, body) => {
  const http = new HttpService(`/invoice/${customerId}/${vehicleId}`);
  return http.post(body);
};

const deleteInvoices = ([id]) => {
  const http = new HttpService(`/invoice/delete/invoices/${[id]}`);
  return http.put();
};

const deleteInvoice = (id) => {
  const http = new HttpService(`/invoice/delete/invoices/${id}`);
  return http.put();
};

const getInvoices = filters => {
  const {
    search,
    vehicle,
    carUser,
    pagination: { current, pageSize },
    sortFilters: { sortField, sortOrder },
  } = filters;
  let queryString = `&q=${search}&limit=${pageSize || '10'}&page=${current || '1'}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&customer=${carUser || ""}&vehicle=${vehicle || ""}`;
  const http = new HttpService(`/invoice?${queryString}`);
  return http.get();
};
const exportInvoices = filters => {
  const {
    search,
    vehicle,
    carUser,
    pagination: { current, pageSize },
    sortFilters: { sortField, sortOrder },
  } = filters;
  let queryString = `&q=${search}&limit=${pageSize || '10'}&page=${current || '1'}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&customer=${carUser || ""}&vehicle=${vehicle || ""}`;
  const http = new HttpService(`/invoice/export-invoices?${queryString}`);
  return http.get();
};

export default { getInvoice, addInvoice, editInvoice, getInvoices, deleteInvoices, deleteInvoice, exportInvoices };
