import React, { useEffect, useState } from 'react';
import AdminAPIs from '../../../api/admin';
import { Col, Row, Button } from 'antd';
// import 'antd/dist/antd.css';
import { RiseOutlined, UserAddOutlined } from '@ant-design/icons';

const SuperAdminDashboard = () => {
  const [count, setCount] = useState({});
  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getUsersCount();
        const { UsersCount } = response.data;
        setCount(UsersCount);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">Dashboard</h2>
        </div>
        <div className="dashboard_iner">
          <Row gutter={48}>
            <Col span={12}>
              <div className="dash_col dash_one">
                <div className="dash_detail">
                  <UserAddOutlined />
                  <a className="font-semibold text-2xl" href="/admin">
                    Admin
                  </a>
                </div>
                <div className="total_count">
                  <h3>{count.admin}</h3>
                  <RiseOutlined />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="dash_col dash_two">
                <div className="dash_detail">
                  <UserAddOutlined />
                  <a className="font-semibold text-2xl" href="/admin/garage-owner">
                    Garage Owner
                  </a>
                </div>
                <div className="total_count">
                  <h3>{count.owner}</h3>
                  <RiseOutlined />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="dash_col dash_three">
                <div className="dash_detail">
                  <UserAddOutlined />
                  <a className="font-semibold text-2xl" href="/admin/business-user">
                    Business User
                  </a>
                </div>
                <div className="total_count">
                  <h3>{count.businessUser}</h3>
                  <RiseOutlined />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="dash_col dash_four">
                <div className="dash_detail">
                  <UserAddOutlined />
                  <a className="font-semibold text-2xl" href="/admin/customer">
                    Customer
                  </a>
                </div>
                <div className="total_count">
                  <h3>{count.customer}</h3>
                  <RiseOutlined />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default SuperAdminDashboard;
