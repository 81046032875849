/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from 'react';
import { Form, Row } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button, Select } from 'antd';
// import FormHeader from '../../../components/FormHeader';
import TextInput from '../../../components/TextInput';
import PropTypes from 'prop-types';
import RequiredAsterisk from '../../../components/RequiredAsterisk';
import PhoneInput, { isPossiblePhoneNumber,isValidPhoneNumber } from 'react-phone-number-input';

import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';
import AdminAPIs from '../../../api/admin';
import { useAlert } from 'react-alert';
import _ from 'lodash';


const GarageFields = ({ operation, submitHandler, data, updateState, setOwner }) => {
  const updatedData = data;
  const [garages, setGarages] = useState([]);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [adminData, setAdminData] = useState({
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    mobile: data?.mobile ?? '',
    email: data?.email ?? '',
    garage: data?.garage ?? '',
    role: 'owner',
  });
  const { Option, OptGroup } = Select;
  const [phone, setPhone] = useState(adminData.mobile);
  adminData.mobile = phone;
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const [errors, setErrors] = useState({
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      garage: '',
     
    });
  // const updateState = e => {
  //   const key = e.target.name;
  //   let value = e.target.value;
  //   setAdminData({ ...adminData, [key]: value });
  // };

  // // To disable submit button at the beginning.
  // useEffect(() => {
  //   forceUpdate({});
  // }, []);
  const [collapsed, setCollapsed] = useState('false');
  /********/

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getGarages();
        // setAdmins(response.data.admins);
        const { garages } = response.data;
        setGarages(garages);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);
  const isValidName = name => {
    // return name && /^[a-zA-Z ]{2,30}$/.test(name);
   // return name && /^[a-zA-Z&'-. ]{2,30}$/.test(name);
   return name && /^[a-zA-Z&'-.][a-zA-Z&'-. ]{1,29}$/.test(name);
  };
  const emailString = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
 
  const handleErrors = () => {
    let err = { ...errors };
    console.log('data', data);

    // Email validation
    if (!data.email?.trim()) err.email = '* Enter email';
    else if (!data.email.match(emailString)) err.email = '* Enter a valid email';
    else err.email = '';

    // First name validation
    if (!data.firstName?.trim()) err.firstName = '* Enter first name';
    else if (!isValidName(data.firstName)) err.firstName = '* Please enter a valid name';
    else if (/^\s/.test(data.firstName)) err.firstName = '* No leading spaces allowed';
    else err.firstName = '';

    // Last name validation
    if (!data.lastName?.trim()) err.lastName = '* Enter last name';
    else if (!isValidName(data.lastName)) err.lastName = '* Please enter a valid name';
    else if (/^\s/.test(data.lastName)) err.lastName = '* No leading spaces allowed';
    else err.lastName = '';

    // Garage validation
    if(operation=='add'){
      if (!data.garage?.trim()) err.garage = '* Enter garage name';
      else if (!isValidName(data.garage)) err.garage = '* Please enter a valid name';
      else if (/^\s/.test(data.garage)) err.garage = '* No leading spaces allowed';
     else err.garage = ''; // ✅ Fix: Corrected field assignment

    }else{

      if (!data.ownerId?.garage?.name?.trim()) err.garage = '* Enter garage name';
      else if (!isValidName(data.ownerId.garage.name)) err.garage = '* Please enter a valid name';
      else if (/^\s/.test(data.ownerId.garage.name)) err.garage = '* No leading spaces allowed';
      else err.garage = ''; // ✅ Fix: Corrected field assignment
    }
    
   

    // Mobile number validation
    if (!data.mobile?.trim()) err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(data.mobile)) err.mobile = '* Enter a valid phone number';
    else err.mobile = '';

    setErrors(err);
  };



  const handleSubmit = e => {
    console.log('operation',operation);
    e.preventDefault();
    handleErrors();
    console.log('errors',errors);
    let garage_data='';
    if(operation==='add'){
      garage_data=data.garage;

    }else{
      garage_data=data.ownerId.garage.name;
    }
    
    if (
          errors.firstName == '' &&
          errors.lastName == '' &&
          errors.email == '' &&
          errors.garage == '' &&
          isValidPhoneNumber(data.mobile) &&
          (data.email &&
            data.firstName &&
            data.lastName &&
            data.mobile &&
            garage_data 
            ) != ''
        ) {
        if (isValidName(data?.firstName) && isValidName(data?.lastName) && isValidName(garage_data)) {
          if (data.mobile && data.mobile.length >= 10 && isValidPhoneNumber(data.mobile)) {
            
            submitHandler(adminData);
          } else {
            handleErrorMessages({ customMessage: `Please enter a valid phone number.` }, alert);
          }
        }}
    
   
  };

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/admin/garages">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">
                {operation === 'add' ? 'Add Garage' : 'Edit Garage'}
              </p>
            </Link>
            {/* </button> */}
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <form className="flex flex-col px-5 py-6 bg-white app_form" onSubmit={e => handleSubmit(e)}>
          <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col input__wrap">
            <TextInput
              label="First Name"
              onChange={updateState}
              name="firstName"
              value={data?.firstName}
              // value={adminData?.firstName}
              required={true}
              placeholder="John"
            />
            {errors && errors.firstName !== '' ? (
                  <p className="text-error font text-sm">{errors.firstName}</p>
                ) : null}
            </div>
            <div className="flex flex-col input__wrap">
            <TextInput
              label="Last Name"
              onChange={updateState}
              name="lastName"
              value={data?.lastName}
              // value={adminData?.lastName}
              required={true}
              placeholder="Doe"
            />
            
            {errors && errors.lastName !== '' ? (
                  <p className="text-error font text-sm">{errors.lastName}</p>
                ) : null}
            </div>
            <div className="flex flex-col input__wrap">
              <label>
                Mobile Number
                <RequiredAsterisk />
              </label>
              <PhoneInput
                label="Mobile"
                international
                countryCallingCodeEditable={true}
                defaultCountry="CA"
                // onChange={setPhone}
                name="mobile"
                value={data?.mobile}
                onChange={e => {
                  // e.preventDefault();
                  setOwner({ ...data, mobile: e });
                }}
                // value={phone != '' ? phone : adminData?.mobile}
                required={true}
                placeholder="123-456-7890"
                maxLength="16"
                minLength="10"
              />
               {errors && errors.mobile !== '' ? (
                  <p className="text-error font text-sm">{errors.mobile}</p>
                ) : null}
            </div>
            <div className="flex flex-col input__wrap">
            <TextInput
              label="Email"
              onChange={updateState}
              name="email"
              value={data?.email}
              // value={adminData?.email}
              required={true}
              placeholder="john.doe@mail.com"
            />
            {errors && errors.email !== '' ? (
                  <p className="text-error font text-sm">{errors.email}</p>
                ) : null}
            </div>
            <div className="flex flex-col input__wrap">
            <TextInput
              label="Garage"
              onChange={updateState}
              name="garage"
              value={data?.ownerId?.garage?.name}
              // value={adminData?.garage}
              required={true}
              placeholder="test"
            />
            {errors && errors.garage !== '' ? (
                  <p className="text-error font text-sm">{errors.garage}</p>
                ) : null}
            </div>
            {/* <label>
              Garages
              <RequiredAsterisk />
            </label>
            <Select
              className=" mb-4 bg-white text-body-2 text-text focus:outline-none rounded"
              label="Garages"
              name="garages"
              // value={customer?.role}
              onChange={e => setAdminData({ ...adminData, garage: e })}
              required={true}
            >
              {garages.map((garage, i) => (
                <option key={i.toString()} value={garage._id}>
                  {_.startCase(garage.name)}
                </option>
              ))}
            </Select> */}
            {/* <TextInput
              label="Street"
              onChange={e => updateState(e)}
              name="street"
              value={admin?.street}
              required={false}
              placeholder="e.g 100 Bayview Ave"
            />
            <TextInput
              label="City"
              onChange={e => updateState(e)}
              name="city"
              value={admin?.city}
              required={false}
              placeholder="Toronto"
            />
            <TextInput
              label="Province"
              onChange={e => updateState(e)}
              name="province"
              value={admin?.province}
              required={false}
              placeholder="Ontario"
            />
            <TextInput
              label="Postal Code"
              onChange={e => updateState(e)}
              name="postalCode"
              value={admin?.postalCode}
              required={false}
              placeholder="e.g M1M 1M1"
            /> */}
          </Row>
          <div className="text-right">
            <button
              type="submit"
              className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              {operation === 'add' ? 'Add Garage' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
GarageFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  data: PropTypes.object,
  updateState: PropTypes.func,
  setOwner: PropTypes.func,
};

export default GarageFields;
