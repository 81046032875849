/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Checkbox, Select, Input, Tooltip, Modal, Collapse } from 'antd';
import {
  DownloadOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  SearchOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { FilterOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { set } from 'lodash-es';
import _ from 'lodash';
import AdminAPIs from '../../../api/admin';
import vehicle from '../../../api/vehicle';
import { handleSuccessMessages } from '../../../helpers/flashMessages';
import { useAlert } from 'react-alert';
const Vehicles = () => {
  const history = useHistory();
  const { Search } = Input;
  const [typingTimeout, setTypingTimeout] = useState(0);
  const { Option, OptGroup } = Select;
  const alert = useAlert();
  const [selectedRows, setSelectedRows] = useState([]);
  const [nameOptions, setNameOptions] = useState([])
  const [collapse, setCollapse] = useState([]);
  const { Panel } = Collapse;
  const [vehicles, setVehicles] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState([]);
  const [name, setName] = useState('');
  const { confirm } = Modal;
  const [options, setOptions] = useState({
    search: '',
    status: '',
    garage: '605b5b226ec4715ce5e0e00d',
    customer: '',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
    start: '',
    end: '',
    years: '',
  });
  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: '#F0734F',
      }}
    />
  );
  const columns = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(vehicles.map(x => x._id)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  vehicles.map(x => x._id),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: '_id',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: _id => <Checkbox onChange={e => onSelectRow(e, _id)} checked={selectedRows.includes(_id)} />,
    },
    {
      title: 'Name',
      dataIndex: 'customer',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend'],
      render: customer => `${customer.firstName} ${customer.lastName}`,
    },
    {
      title: 'Plate No',
      key: 'plateNumber',
      dataIndex: 'plateNumber',
      sorter: true,
    },
    {
      title: 'Vin No',
      key: 'vin',
      dataIndex: 'vin',
      sorter: true,
    },
    {
      title: 'Make',
      key: 'make',
      dataIndex: 'make',
      sorter: true,
    },
    {
      title: 'Model',
      key: 'model',
      dataIndex: 'model',
      sorter: true,
    },
    {
      title: 'Year',
      key: 'yearManufactured',
      dataIndex: 'yearManufactured',
      sorter: true,
    },
    {
      title: 'Engine',
      key: 'engine',
      dataIndex: 'engine',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      sorter: true,
      render: active => `${active ? 'Active' : 'Inactive'}`,
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      // eslint-disable-next-line react/display-name
      render: _id => (
        <>
          <Link to={`/admin/vehicle/edit/${_id}`}>
            <Tooltip placement="top" title="edit">
              <EditOutlined />
            </Tooltip>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, _id)} />
          </Tooltip>
        </>
      ),
    },
  ];
  const onSearch = value => console.log(value);
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const getVehicleList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getAllVehicles(options);
      const { vehicles } = response.data;
      setVehicles(vehicles.docs);
      setStats(vehicles.stats)
      setOptions({
        ...options,
        pagination: {
          current: vehicles.page,
          pageSize: vehicles.count,
          total: vehicles.totalDocs,
          showSizeChanger: true,
        },
      });
    } catch (error) {
      console.error('Customer Error', error);
    }
    setLoading(false);
  };
  const onSelectAll = e => {
    if (e.target.checked) {
      const test = [...new Set([...selectedRows, vehicles.map(n => n._id)].flat())];
      setSelectedRows(test);
    } else {
      const selectedIds = selectedRows.filter(n => !vehicles.map(x => x._id).includes(n));
      setSelectedRows(selectedIds);
    }
  };
  const onSelectRow = async (e, _id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, _id]);
    } else {
      const selectedIds = selectedRows.filter(n => n !== _id);
      setSelectedRows(selectedIds);
    }
  };
  
  function onChange(pagination, filters, sorter, extra) {
    const updatedOptions = { ...options, pagination };
    setOptions(updatedOptions);

    getVehicleList({
      ...updatedOptions,
      sortFilters: {
        sortField: sorter?.columnKey,
        sortOrder: sorter?.order,
      },
    });
  }
  const filterSortedTable = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const query = e.target.value;
    const updatedOptions = {
      ...options,
      search: query.trim(),
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    setTypingTimeout(() =>
      setTimeout(() => {
        getVehicleList(updatedOptions);
      }, 1000)
    );
  };
  const collapseShowHideFunc = key => {
    setCollapse(key);
  };
  const onSelect = e => {
    const newOptions = {
      search: options.search,
      status: options.status,
      years:options.years,
      customers: e,
      pagination: options.pagination,
      sortFilters: options.sortFilters,
      start: options.start,
      end: options.end,
    };
    setOptions({ ...options, customer: e });
    getVehicleList(newOptions);
  };
  useEffect(() => {
    getVehicleList(options);
  }, []);
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await AdminAPIs.getAllCustomerExport(options);
  //       const { customers } = response.data.customers;
  //       setCustomers(customers.docs);       
  //     } catch (e) {
  //       console.error('error in catch', e);
  //     }
  //   })();
  // }, []);
  useEffect(() => {
    (async () => {
      try {
       // const response = await AdminAPIs.getCustomersName();
        const response = await AdminAPIs.getCustomersAndBusinessUsersName();
        const { usersName } = response.data;
        setNameOptions(usersName);
      } catch (e) {
        console.error('error in catch', e);
      }
    })();
  }, []);
  const handleFilterClick = () => {
    setCollapse([]);
    getVehicleList(options);
  };
  const handleClearAllClick = () => {
    setCollapse([]);
    const updatedOptions = {
      ...options,
      status: '',
      customer: '',
      years: '',
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    getVehicleList(updatedOptions);
  };

  const showDeleteConfirm = (e, _id) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        const response = AdminAPIs.deleteVehicle(_id);
        handleSuccessMessages({ customMessage: `Vehicle deleted successfully.` }, alert);
        setTimeout(() => {
          getVehicleList(options);
        }, 1000)
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };

  const showDelete = (e, selectedRows) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        const response = AdminAPIs.deleteVehicles([selectedRows]);
        handleSuccessMessages({ customMessage: `Vehicles deleted successfully.` }, alert);
        setTimeout(() => {
          getVehicleList(options);
        }, 1000)
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">Vehicles</h2>
          <div className="right--btn">
            <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
              <Link to="/admin/vehicle/add">
                <FiPlus className="inline-block align-middle mr-2 text-base" />
                <p className="inline-block align-middle">Add Vehicle</p>
              </Link>
            </button>
          </div>
        </div>
        <div className="d-flex filter--wrap my-5">
          <div className="d-flex mb-0 gap-2 mb-sm-0">
            <div className="del--icon">
              <DeleteOutlined onClick={e => showDelete(e, selectedRows)} />
            </div>
            <div className="filter_content w-100 d-flex">
              <Collapse activeKey={collapse} onChange={collapseShowHideFunc}>
                <Panel
                  showArrow={false}
                  header={
                    <>
                      <FilterOutlined className="mr-2" />
                      Filters
                    </>
                  }
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-12 filter_header d-flex flex-wrap position-relative">
                        <h4 className="title">Filter By</h4>
                        <button className="clearfilter_btn btn-default" onClick={handleClearAllClick}>
                          Clear all
                        </button>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Status</label>
                        <Select
                          className="text-1 font-normal rounded-r-none active-inactive-filter"
                          name="status"
                          defaultValue="All"
                          value={options.status}
                          onChange={e => setOptions({ ...options, status: e })}
                        >
                          <Option value="">All</Option>
                          <Option value={true}>Active</Option>
                          <Option value={false}>Inactive</Option>
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Customer / Business Users</label>
                        {/* <Select
                        showSearch
                        placeholder="Select Customer"
                        optionFilterProp="children"
                        defaultValue={'All'}
                        value={options.customer}
                        onSelect={e => {
                          onSelect(e);
                        }}
                        onChange={e => {
                          setOptions({ ...options, customer: e });
                        }}
                        onSearch={onSearch}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {nameOptions?.length>0 ? nameOptions.map((customer, i) => (
                          <option key={i.toString()} value={customer.userId}>
                            {_.startCase(customer.firstName).concat(' ').concat(customer.lastName)}
                          </option>
                        )): ''}
                      </Select> */}
                        <Select
                          showSearch
                          optionFilterProp="children"
                          label="Customer"
                          className="text-1 font-normal rounded-r-none active-inactive-filter"
                          name="customer"
                          defaultValue={'All'}
                          value={options.customer}
                          onChange={e => setOptions({ ...options, customer: e })}
                        >
                          <Option value="">All</Option>
                          {nameOptions.map((customer, i) => (
                            <Option key={i.toString()} value={customer.id}>
                              {_.startCase(customer.firstName).concat(' ').concat(customer.lastName)}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Years Old</label>
                        <Select
                          className="text-1 font-normal rounded-r-none active-inactive-filter"
                          name="years"
                          defaultValue={'All'}
                          value={options.years}
                          onChange={e => setOptions({ ...options, years: e })}
                        >
                          <Option value={''}>All</Option>
                          <Option value={'0-4'}>0-4 Years</Option>
                          <Option value={'5-15'}>5-15 Years</Option>
                          <Option value={'16-25'}>16-25+ Years</Option>
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell d-flex justify-content-end">
                        <label className="label"></label>
                        <button
                          type="button"
                          className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm"
                          onClick={handleFilterClick}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>

          <div className="d-flex">
            <Search
              className="search--wrap  input-search-box   mt-0 mt-sm-0"
              style={{ width: '270px' }}
              placeholder="input search text"
              // enterButton="Search"
              size="large"
              // addonBefore={suffix}
              onChange={e => filterSortedTable(e)}
              // onSearch={onSearch}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center px-3 pb-3">
        <p className="mr-3 font-weight-bold">Total Vehicles: <span className="text-primary">{stats.totalVehicles}</span></p>
        <p className="mr-3 font-weight-bold">Vehicles from 0-4 years: <span className="text-primary">{stats.totalVehicles0to4Years}</span></p>
        <p className="mr-3 font-weight-bold">Vehicles from 5-15 years: <span className="text-primary">{stats.totalVehicles5to15Years}</span></p>
        <p className="font-weight-bold">Vehicles from 16-25+ years: <span className="text-primary">{stats.totalVehicles16plusYears}</span></p>
      </div>
      <main className="overflow-y-auto main--table">
        <div className="grid mx-auto">
          <div className="w-full mb-8 overflow-hidden shadow-xs">
            <div className="w-full overflow-x-auto">
              <Table
                columns={columns}
                dataSource={vehicles}
                sortDirections={['ascend', 'descend']}
                onChange={onChange}
                pagination={options.pagination.total > 10 ? options.pagination : false}
                loading={loading}
                scroll={{ scrollToFirstRowOnChange: true }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Vehicles;
