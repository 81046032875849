/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Form, Row, Select, AutoComplete } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import TextInput from '../../../../components/TextInput';
import RadioInput from '../../../../components/RadioInput';
import SelectInput from '../../../../components/SelectInput';
import SelectInputFields from '../../../../components/SelectInputFields'
import CurrencyInput from '../../../../components/CurrencyInput';
import PropTypes from 'prop-types';
import RequiredAsterisk from '../../../../components/RequiredAsterisk';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import CreatableSelect from 'react-select/creatable';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import AdminAPIs from '../../../../api/admin';
import GarageAPIs from '../../../../api/garage';
import { useAlert } from 'react-alert';
import _ from 'lodash';
import ReactAutocomplete from 'react-autocomplete';
import { keyupValidation } from '../../../../helpers/validationMessages';
import ReactQuill from 'react-quill';

const UserFields = ({
  operation,
  submitHandler,
  data,
  updateState,
  setAdmin,
  admin,
  phone,
  setPhone,
  setUser,
  user,
  isApiCall,
}) => {
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      padding: "8px 10px",
      border: "none"
    })
  };
  
  const history = useHistory() 
  const alert = useAlert();
  const [form] = Form.useForm();
  const [garages, setGarages] = useState([]);
  const [options, setOptions] = useState([]);
  const [lastNameSuggestions, setLastNameSuggestions] = useState([])
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    garage: '',
    discount: '',
    labourRate: '',
  });
  const NotesRef = useRef();
  const modules = {
    toolbar: false,
  };
  // const [user, setUser] = useState({
  //   firstName: '',
  //   lastName: '',
  //   mobile: '',
  //   email: '',
  //   role: data?.role ?? '',
  //   ...data,
  //   customerId: {
  //     city: '',
  //     province: '',
  //     street: '',
  //     postalCode: '',
  //     labourRate: '',
  //     garage: '',
  //     status: false,
  //     discount: '',
  //     company: '',
  //     ...data.customerId,
  //   },
  // });
  const discountLevel = [0, 5, 10, 15];
  const [, forceUpdate] = useState({});
  const { Option, OptGroup } = Select;
  const customerIdFields = [
    'province',
    'postalCode',
    'labourRate',
    'street',
    'city',
    'notes',
    'status',
    'discount',
    'company',
  ];
  const emailString = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  // data.mobile = phone;
  const results = localStorage.getItem('usersName');
  var names = [JSON.parse(results) || []];
  const firstNameOptions = names[0].map((items, i) => {
    return { label: [items.firstName], value: items.firstName, data: items };
  });
  const lastNameOptions = names[0].map((items, i) => {
    return { label: [items.lastName], value: items.lastName, data: items };
  });
  function handleChange(value) {}

  const [collapsed, setCollapsed] = useState('false');
  /********/
  const checkCharacterCount = (event, refValue) => {
    const unprivilegedEditor = refValue.current.editor;
    if (unprivilegedEditor.getLength() > 600 && event.key !== 'Backspace') event.preventDefault();
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await GarageAPIs.getGarages();
        const { garages } = response.data;
        const garageOptions = garages.map((item, i) => {
          return { label: item.name, value: item._id };
        });

        setGarages(garageOptions);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);
 
  // const updateState = e => {
  //   const key = e.target.name;
  //   let value = e.target.value;
  //   const isCustomerIdField = customerIdFields.includes(key);
  //   if (isCustomerIdField) {
  //     if (key === 'status') {
  //       value = value === 'true'; // converts the string into a Boolean value
  //     }

  //     setUser({ ...user, customerId: { ...user.customerId, [key]: value } });
  //   } else {
  //     setUser({ ...user, [key]: value });
  //   }
  // };

  const isValidName = name => {
    // /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/
    //return name && /^[a-zA-Z ]{2,30}$/.test(name);
    //return name && /^[a-zA-Z&'-. ]{2,30}$/.test(name);
    //return name && /^[a-zA-Z&'-.][a-zA-Z&'-. ]{1,29}$/.test(name);
   // return name && /[a-zA-Z]/.test(name) && /^[a-zA-Z &@#%$*!'-.]{2,30}$/.test(name.trim());
   return (
    name &&
    /[a-zA-Z]/.test(name) && // Ensure at least one letter
    /^[a-zA-Z !"#$%&'()*+,\-./:;=?@[\]\\^_`{|}~]{2,30}$/.test(name.trim()) // Allows all except < >
   );
  
    

  };
 
  const handleErrors = e => {
    let mobile = phone ? phone : data.mobile;
    let err = { ...errors };
    if (data.role == '') err.role = '* Select role';
    else if (data.role == 'Please Select') err.role = '* Select role';
    else if (data.role != '') err.role = '';
    if (data.email == '') err.email = '* Enter email';
    else if (!data.email.match(emailString)) err.email = '* Enter a valid email';
    else if (data.email != '') err.email = '';
    if (data.firstName == '') err.firstName = '* Enter first name';
    if (data.firstName !== '' && !isValidName(data.firstName)) err.firstName = '* Please enter a valid name';
    else if (data.firstName != '') err.firstName = '';
    if (data.lastName == '') err.lastName = '* Enter last name';
    if (data.lastName !== '' && !isValidName(data.lastName)) err.lastName = '* Please enter a valid name';
    else if (data.lastName != '') err.lastName = '';
    if (data.role == 'customer' || data.role == 'business_user') {
      if (data.labourRate == '') err.labourRate = '* Enter labour rate';
      if (data.labourRate != '' && Math.sign(data?.labourRate) < 1) err.labourRate = '* Please enter valid labour rate';
      if (data.labourRate != '') err.labourRate = '';
    if (data.discount != '' && Math.sign(data?.labourRate) === 1) err.discount = '';
      if (data.discount == '') err.discount = '* Select discount';
      if (data.discount == 'Please Select') err.discount = '* Select discount';
      else if (data.discount != '') err.discount = '';
    }
    if (data.role == 'customer' || data.role == 'business_user' || data.role == 'owner') {
      if (data.garage == '') err.garage = '* Select garage';
      if (data.garage != '') err.garage = '';
    }
    if (mobile == '') err.mobile = '* Enter mobile number';
    else if (!isPossiblePhoneNumber(mobile)) err.mobile = '* Enter valid phone number';
    setErrors(err);
  };

  const garageError = ()=>{
    if (data.garage == '') setErrors((prev) => ({ ...prev, ['garage']: ` * Select garage` }));
    else if (data.garage != '') setErrors((prev) => ({ ...prev, ['garage']: `` }));
  }
  const discountError = (event)=>{
  
    if (data.discount == '') setErrors((prev) => ({ ...prev, ['discount']: ` * Select discount` }));
    if (data.discount == 'Please Select') setErrors((prev) => ({ ...prev, ['discount']: ` * Select discount` }));
    else if (data.discount != '') setErrors((prev) => ({ ...prev, ['discount']: `` }));
   
  }

  
  const onChangeError = (event)=>{
    event.persist()

    const decimalRegex = /^\d{1,}(\.\d{0,4})?$/;
    const { name,value} = event.target;
    console.log(name,typeof value ,data)
    if(event.target.name==='labourRate'){
      if (data.labourRate == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter labour rate` }));
      else if (data?.customerId?.labourRate.toString() != ''&& data?.customerId?.labourRate.toString().match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
      else if(!data?.labourRate?.match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter valid labour rate` }));
      else if (data?.labourRate != ''&& data?.labourRate.match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
      // if (data.labourRate != '' && Math.sign(data?.labourRate) < 1) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter valid labour rate` }));
      // if (data.discount != '' && Math.sign(data?.labourRate) === 1) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
      
    }
    if(event.target.name =='role')
    {
      if (event.target.value == 'Please Select') {

    setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Select role` }));
    }
      else if(event.target.value !== 'Please Select')
      {
        setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
      }
    }
    

     if (event.target.name === "firstName"){
       if (event.target.value == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter first name` }));
       else if (event.target.value !== '' && !isValidName(event.target.value)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
       else if (event.target.value != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
     }
 
     if(event.target.name === "lastName"){
     if (event.target.value == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter last name` }));
     else if (event.target.value !== '' && !isValidName(event.target.value)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
     else if (event.target.value != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
     }

     if(event.target.name === "mobile"){
      if(event.target.value == ''||phone == undefined || phone == '' && data.mobile == '') setErrors((prev)=>({...prev, [event?.target?.name]:`* Enter mobile number`}))
      else if (!isValidPhoneNumber((phone || data.mobile))) setErrors((prev)=>({...prev, [event?.target?.name]:`* Enter valid phone number`}))
      else if (isValidPhoneNumber(phone || data.mobile)) setErrors((prev)=>({...prev, [event?.target?.name]:''}))
     }

     if(event.target.name === 'email'){
      if (data.email == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter email` }));
      else if (!data.email.match(emailString)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter a valid email` }));
      else if (data.email != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
    }
   }

  const handleSearch = async (value) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if(value){
    setTypingTimeout(() =>
      setTimeout(async () => {
        const response = await AdminAPIs.getFirstNameSuggestions(value);
        const {usersName} = response.data;
        let firstNames = [];
        let lastNames = [];
        firstNames = usersName && usersName?.map((data, i) => {
          lastNames.push({key :i, value:data.lastName,})
          return { key :i, value:data.firstName, obj: data}
          //  return {'key': i.toString(), 'value' : data.firstName}
          })
        setOptions(firstNames);
        setLastNameSuggestions(lastNames);
        // setFirstInputName(value)
      }, 1000)
    )};
  };

  const onChnageLastName =(val, obj)=>{
    setAdmin({...admin, lastName: val})
  }

  const handlePhone = (e) => {
    setPhone(e);
    handlePhoneErrors(e)
  }
  const handlePhoneErrors = (e) => {
    let err = { ...errors };
    let phone = e;
    if (phone == undefined || phone == '' && data.mobile == '') err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(phone)) err.mobile = '* Enter valid phone number';
    else if ( isValidPhoneNumber(phone)) err.mobile = '';
    setErrors(err)
  }



  const handleSubmit = e => {
    e.preventDefault();
    console.log('data', data);
   // return;
      
    data.mobile = phone || data.mobile;
    setIsValidFirstName(isValidName(data?.firstName));
    setIsValidLastName(isValidName(data?.lastName));
    handleErrors();
    if(data.role == 'customer' || data.role == 'business_user')
    {
       if(data.discount == '' || data.discount == 'Please Select')
       {
         return
       }
    }
    
    
    if (data.role == 'customer' || data.role == 'business_user') {
        
      if (
        errors.firstName == '' &&
        errors.lastName == '' &&
        errors.email == '' &&
        errors.labourRate == '' &&
        errors.discount == '' &&
        errors.garage == '' &&
        errors.mobile == ''
        // isPossiblePhoneNumber(data.mobile) && 
        // data.mobile != ''
      ) {
        
      if (isValidName(data?.firstName) && isValidName(data?.lastName)) {
          if (data.mobile && data.mobile.length >= 10 && isValidPhoneNumber(data.mobile)) {
            const updatedUser = { ...data, mobile: phone };
            submitHandler(updatedUser);
          } else {
            handleErrorMessages({ customMessage: `Please enter a valid phone number.` }, alert);
          }
        }
      }
    } else if (data.role == 'owner') {
      if (
        errors.firstName == '' &&
        errors.lastName == '' &&
        errors.email == '' &&
        errors.garage == '' &&
        isValidPhoneNumber(data.mobile) && 
        data.mobile != ''
      ) {
        
        if (isValidName(data?.firstName) && isValidName(data?.lastName)) {
          // if (data.mobile && data.mobile.length >= 10 && isPossiblePhoneNumber(data.mobile)) {
            const updatedUser = { ...data, mobile: phone };
            submitHandler(updatedUser);
          // } else {
          //   handleErrorMessages({ customMessage: `Please enter a valid phone number.` }, alert);
          // }
        }
      }
    } else if (data.role == 'admin') {
      if (
        errors.firstName == '' &&
        errors.lastName == '' &&
        errors.email == '' &&
        isValidPhoneNumber(data.mobile) && 
        data.mobile != ''
      ) {
        
        if (isValidName(data?.firstName) && isValidName(data?.lastName)) {
          if (data.mobile && data.mobile.length >= 10 && isValidPhoneNumber(data.mobile)) {
            const updatedUser = { ...data, mobile: phone };
            
            submitHandler(updatedUser);
          } else {
            handleErrorMessages({ customMessage: `Please enter a valid phone number.` }, alert);
          }
        }
      }
    }
  };
  const onChange = name => {
    
    if(operation == 'edit'){
      data.customerId.garage = name;
      setUser({ ...user, customerId: { ...user.customerId, garage: name } });
    }else {
      data.garage = name;
    }
  };

  const onSearch = value => {};

  const handleOnCancelClick = e => {
    e.preventDefault();
    history.push('/admin/all-users')
  }

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/admin/all-users">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">{operation === 'add' ? 'Add User' : 'Edit User'}</p>
            </Link>
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <form
          className="flex flex-col px-5 py-6 bg-white app_form"
          onSubmit={e => handleSubmit(e)}
          noValidate
        >
          <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
            <div>
              {operation == 'add' ? (
                <>
                  <SelectInput
                    label="User Type"
                    name="role"
                    onChange={updateState}
                    value={data?.role}
                    required
                    placeholder="select type"
                    onBlur={onChangeError}
                    onKeyPress={onChangeError}
                  >
                    <option value="admin">Admin</option>
                    <option value="business_user">Business User</option>
                    <option value="customer">Customer</option>
                    <option value="owner">Garage Owner</option>
                  </SelectInput>
                  {errors && errors.role !== '' ? (
                    <p className="text-error font text-sm">{errors.role}</p>
                  ) : null}
                </>
              ) : (
                <>
                  <TextInput
                    label="User Type"
                    disabled
                    name="role"
                    onChange={updateState}
                    value={data?.role == 'customer'? 'Customer' : data?.role == 'owner' ? 'Garage Owner': data?.role == 'admin'?'Admin': 'Business User'}
                    required
                    placeholder="select type"
                  ></TextInput>

                  {errors && errors.role !== '' ? (
                    <p className="text-error font text-sm">{errors.role}</p>
                  ) : null}
                </>
              )}
            </div>
            {data?.role === 'business_user' && (
              <TextInput
                label="Company"
                onChange={updateState}
                name="company"
                value={data?.customerId?.company}
                required={false}
                placeholder="Company Name"
              />
            )}
            <div className="flex flex-col input__wrap">
              {/* <label className="inline text-small text-text mb-1">
                First Name
                <RequiredAsterisk />
              </label>
              <AutoComplete
              className=" bg-white text-body-2 text-text focus:outline-none rounded"
            onSearch={handleSearch}
            onChange={(val) => setAdmin({...admin, firstName: val})}
            onSelect={(val, options) => {
              const userData = options?.obj || {};
              const customMobile = options?.obj ? options.obj.mobile : "";
              (setAdmin || setAdmin)({ ...(admin || data), firstName: val, ...userData});
              setPhone(customMobile)
                            // handleAdminValidation(userData)
            }}
            value={data.firstName}
            placeholder="first name"
            options={options}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            />
              {/* <CreatableSelect
                isClearable
                inputProps={{
                  required: true,
                  pattern: '^[a-zA-Z ]{2,30}$',
                  onInvalid: 'this.setCustomValidity("First name must be alphabetical and non-empty")',
                }}
                options={firstNameOptions}
                name="firstName"
                value={{value: data?.firstName, label: data?.firstName}}
                onChange={val => {
                  data.firstName = val?.value;
                  const userData = val?.data || {};
                  (setAdmin || setUser)({ ...(admin || data), firstName: val?.value, ...userData});
                }}
                styles={customStyles}
                onKeyDown={keyupValidation}
              /> */}
              <TextInput
                label="First Name"
                // onChange={val => {
                //   data.firstName = val?.value;
                // }}
                onChange={updateState}
                name="firstName"
                value={data?.firstName}
                required
                placeholder="First name"
                onBlur={onChangeError}
                onKeyPress={onChangeError}
              />
              {errors && errors.firstName !== '' ? (
                <p className="text-error font text-sm">{errors.firstName}</p>
              ) : null}
            </div>
            <div className="flex flex-col input__wrap">
              {/* <label className="inline text-small text-text mb-1">
                Last Name
                <RequiredAsterisk />
              </label>
              <AutoComplete
              className=" bg-white text-body-2 text-text focus:outline-none rounded"
              value={data?.lastName}
            onChange={onChnageLastName}
            onSelect={(val, options) => {
              setAdmin({...admin, lastName: val})
              // handleAdminValidation(userData)
            }}
            placeholder="last name"
            options={lastNameSuggestions}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            />
              {/* <CreatableSelect
                isClearable
                inputProps={{
                  required: true,
                  pattern: '^[a-zA-Z ]{2,30}$',
                  oninvalid: 'this.setCustomValidity("Last name must be alphabetical and non-empty")',
                }}
                options={lastNameOptions}
                name="lastName"
                value={{value: data?.lastName, label: data?.lastName}}
                onChange={val => {
                  data.lastName = val?.value;
                  const userData = val?.data || {};
                  (setAdmin || setUser)({ ...(admin || data), lastName: val?.value, ...userData });
                }}
                styles={customStyles}
                onKeyDown={keyupValidation}
              /> */}

              <TextInput
                label="last Name"
                onChange={updateState}
                name="lastName"
                value={data?.lastName}
                required={true}
                placeholder="Last name"
                onBlur={onChangeError}
                onKeyPress={onChangeError}
              />

              {errors && errors.lastName !== '' ? (
                <p className="text-error font text-sm">{errors.lastName}</p>
              ) : null}
            </div>
            {/*<TextInput
              label="First Name"
              onChange={updateState}
              name="firstName"
              value={data?.firstName}
              required={true}
              placeholder="John"
            />
            <TextInput
              label="Last Name"
              onChange={updateState}
              name="lastName"
              value={data?.lastName}
              required={true}
              placeholder="Doe"
              />*/}
            <div>
              <label>
                Mobile Number
                <RequiredAsterisk />
              </label>
              <PhoneInput
                label="Mobile"
                international
                countryCallingCodeEditable={true}
                defaultCountry="CA"
                // onChange={e => {
                //   // e.preventDefault();
                //   setUser({ ...data, mobile: e });
                // }}
                onChange={e => handlePhone(e)}
                name="mobile"
                value={phone != '' ? phone : data?.mobile}
                required={true}
                placeholder="123-456-7890"
                maxLength="16"
                minLength="10"
                onBlur={onChangeError}
                onKeyPress={onChangeError}
              />
              {errors && errors.mobile !== '' ? (
                <p className="text-error font text-sm">{errors.mobile}</p>
              ) : null}
            </div>
            <div>
              <TextInput
                label="Email"
                onChange={e => updateState(e, 'email')}
                name="email"
                value={data?.email}
                required={true}
                placeholder="john.doe@mail.com"
                onBlur={onChangeError}
                onKeyPress={onChangeError}
                // onkeypress={(e) => {keyupValidation(e, 'email')}}
              />
              {errors && errors.email !== '' ? (
                <p className="text-error font text-sm">{errors.email}</p>
              ) : null}
            </div>
            {data.role === 'owner' && (
              <>
                <div>
                  <TextInput
                    label="Garage"
                    onChange={e => updateState(e, 'garage')}
                    name="garage"
                    value={data?.garage || data?.ownerId?.garage?.name}
                    required={true}
                    placeholder="test"
                    onKeyPress={garageError}
                    onBlur={garageError}
                  />
                  {errors && errors.garage !== '' ? (
                    <p className="text-error font text-sm">{errors.garage}</p>
                  ) : null}
                </div>

                <div>
                  <TextInput
                    label="Street"
                    onChange={updateState}
                    name="street"
                    defaultValue={data?.ownerId?.street}
                    required={false}
                    placeholder="e.g 100 Bayview Ave"
                  />
                </div>
                <div>
                  <TextInput
                    label="City"
                    onChange={updateState}
                    name="city"
                    defaultValue={data?.ownerId?.city}
                    required={false}
                    placeholder="Toronto"
                  />
                </div>
                <div>
                  <TextInput
                    label="Province"
                    onChange={updateState}
                    name="province"
                    defaultValue={data?.ownerId?.province}
                    required={false}
                    placeholder="Ontario"
                  />
                </div>
                <div>
                  <TextInput
                    label="Postal Code"
                    onChange={updateState}
                    name="postalCode"
                    defaultValue={data?.ownerId?.postalCode}
                    required={false}
                    placeholder="e.g M1M 1M1"
                    maxLength="11"
                  />
                </div>
                <div className="flex flex-row">
                  <label className="inline text-small text-text mb-1">Status</label>
                  <RadioInput
                    label="Active"
                    defaultChecked={data?.status === true}
                    onClick={e => updateState(e)}
                    value="true"
                    name="status"
                  />
                  <RadioInput
                    label="Inactive"
                    defaultChecked={data?.status === false}
                    onClick={e => updateState(e)}
                    value="false"
                    name="status"
                  />
                </div>
                <div className="flex flex-col  w-full col-span-full">
                  <label className="text-small text-text mb-2">
                    <div>
                      Notes
                      {<span className="grey_text"> (max 600 characters) </span>}
                    </div>
                  </label>
                  <ReactQuill
                    className="notes-editor-height"
                    onKeyDown={e => checkCharacterCount(e, NotesRef)}
                    ref={NotesRef}
                    name="notes"
                    modules={modules}
                    // onChange={values=> updateState(values,"name")}
                    onChange={value => {
                      if (operation == 'add') {
                        setAdmin({
                          ...admin,
                          notes: value,
                        });
                      } else {
                        setUser({
                          ...user,
                          notes: value,
                        });
                      }
                    }}
                    defaultValue={data?.ownerId?.notes}
                  />
                </div>
              </>
            )}
            {['customer', 'business_user'].includes(data.role) && (
              <>
                <div className="flex flex-row">
                  <label className="inline text-small text-text mb-1">Status</label>
                  <RadioInput
                    label="Active"
                    defaultChecked={
                      operation === 'edit' ? data?.customerId?.status === true : data.status === true
                    }
                    onClick={e => updateState(e)}
                    value="true"
                    name="status"
                  />
                  <RadioInput
                    label="Inactive"
                    defaultChecked={
                      operation === 'edit' ? data?.customerId?.status === false : data.status === false
                    }
                    onClick={e => updateState(e)}
                    value="false"
                    name="status"
                  />
                </div>
                {/* <SelectInput
                  label="Garage"
                  name="garage"
                  onChange={updateState}
                  value={data?.customerId?.garage}
                  required
                  placeholder="select garage"
                > */}
      
                <div className="flex flex-col">
                  <label className="inline text-small text-text mb-1">
                    Garage
                    <RequiredAsterisk />
                  </label>
                  <Select
                    showSearch
                    placeholder="Select garage"
                    optionFilterProp="children"
                    onChange={onChange}
                    onKeyPress={garageError}
                    onBlur={garageError}
                    onClick={garageError}
                    value={data?.customerId?.garage}
                    // required={data?.customerId?.garage === 'undefined' ? true:false}
                    options={garages}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  />
                  {errors && errors.garage !== '' ? (
                    <p className="text-error font text-sm">{errors.garage}</p>
                  ) : null}
                </div>
                {/* </SelectInput> */}
                <TextInput
                  label="Street"
                  onChange={updateState}
                  name="street"
                  value={data?.customerId?.street}
                  required={false}
                  placeholder="e.g 100 Bayview Ave"
                />
                <TextInput
                  label="City"
                  onChange={updateState}
                  name="city"
                  value={data?.customerId?.city}
                  required={false}
                  placeholder="Toronto"
                />
                <TextInput
                  label="Province"
                  onChange={updateState}
                  name="province"
                  value={data?.customerId?.province}
                  required={false}
                  placeholder="Ontario"
                />
                <TextInput
                  label="Postal Code"
                  onChange={updateState}
                  name="postalCode"
                  value={data?.customerId?.postalCode}
                  required={false}
                  placeholder="e.g M1M 1M1"
                  maxLength="11"
                />
                <div>
                  <SelectInput
                    label="Discount Level"
                    name="discount"
                    value={data?.customerId?.discount}
                    onChange={e => updateState(e, 'discount')}
                    required={true}
                    defaultOption="Select Discount Level"
                    onBlur={discountError}
                    onKeyPress={discountError}
                    onClick={discountError}
                  >
                    {discountLevel.map((level, i) => (
                      <option key={i.toString()} value={level}>
                        {level + '%'}
                      </option>
                    ))}
                  </SelectInput>
                  {errors && errors.discount !== '' ? (
                    <p className="text-error font text-sm">{errors.discount}</p>
                  ) : null}
                </div>
                <div>
                  {/* <CurrencyInput
                  label="Labour Rate / Hour"
                  onChange={e => updateState(e, 'labourRate')}
                  name="labourRate"
                  value={data?.customerId?.labourRate}
                  required={true}
                  placeholder="0.00"
                  onkeypress={keyupValidation}
                  onBlur={handleErrors}
                /> */}
                  <TextInput
                    label="Labour Rate / Hour"
                    onChange={e => updateState(e, 'labourRate')}
                    value={admin?.labourRate || data?.customerId?.labourRate}
                    name="labourRate"
                    required={true}
                    placeholder="0.00"
                    onKeyPress={onChangeError}
                    onBlur={onChangeError}
                    // step="0.01"
                  />
                  {errors && errors.labourRate !== '' ? (
                    <p className="text-error font text-sm">{errors.labourRate}</p>
                  ) : null}
                </div>
              </>
            )}
            {/* <div className="flex flex-col">
              <label className="text-small text-text">Status</label>
              <div className="flex flex-row mt-3 mb-10">
                <RadioInput
                  label="Active"
                  defaultChecked={data?.status === true}
                  onClick={updateState}
                  value="true"
                  name="status"
                />
                <RadioInput
                  label="Inactive"
                  defaultChecked={data?.status === false}
                  onClick={updateState}
                  value="false"
                  name="status"
                />
              </div>
            </div> */}
          </Row>
          <div className="text-right">
            <button
              onClick={handleOnCancelClick}
              className="px-5 py-3 mt-5 mx-2 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
              disabled={isApiCall}
            >
              {operation === 'add'
                ? isApiCall
                  ? 'Updating...'
                  : 'Add User'
                : isApiCall
                ? 'Updating...'
                : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
UserFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.string,
  updateState: PropTypes.func,
  setAdmin: PropTypes.func,
  admin: PropTypes.object,
  setUser: PropTypes.func,
  user: PropTypes.object,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  isApiCall: PropTypes.bool
};

export default UserFields;